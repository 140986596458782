<template>
    <div>
        <el-steps :active="importForm.active" finish-status="success" style="padding: 0 10%">
            <el-step title="上传文件"></el-step>
            <el-step title="执行导入"></el-step>
            <el-step title="导入完成"></el-step>
        </el-steps>
        <first-step v-if="importForm.active === 0" :schools="schools" :importForm="importForm" ref="importRef" />
        <second-step
            v-if="importForm.active === 1"
            :import-form="importForm"
            :key-map="keyMap"
        />
        <el-result v-if="importForm.active === 2" icon="success" title="批量导入完成" :subTitle="importForm.successData !== undefined ? `本次成功导入${importForm.successData}条数据` : ''">
            <template slot="extra">
                <el-button type="primary" @click="close">完成</el-button>
            </template>
        </el-result>
    </div>
</template>

<script>
    import firstStep from "./firstStep";
    import secondStep from "./secondStep";

    export default {
        props: {
            schools: {
                type: Array,
            },
            importForm: {
                type: Object,
            },
            keyMap: {
                type: Object,
            }
        },
        components: {
            firstStep,
            secondStep,
        },
        methods: {
            handleUpload(file){
                // this.$emit("handleUpload",file)
            },
            close() {
                this.$emit('close')
            }
        }
    };
</script>

<style lang="scss" scoped>
    /deep/ .el-step {
        .is-success .el-step__icon-inner {
            color: #FFFFFF;
        }

        .is-success .is-text {
            background-color: #66bfff;
            border: 2px solid #66bfff;
        }

        .is-success.el-step__title {
            color: #66bfff;
        }

        .el-step__head.is-success {
            color: #66bfff !important;
            border-color: #66bfff !important;
        }

        .is-success .el-step__line {
            background: #66bfff;
            border-radius: 1px 0px 0px 1px;
        }

        .is-process .el-step__line {
            background: linear-gradient(90deg, #66B3FF 0%, rgba(102, 179, 255, 0.1) 100%);
            border-radius: 1px 0px 0px 1px;
        }

        .is-process .el-step__icon-inner {
            color: #66bfff;
        }

        .is-process .is-text {
            border: 2px solid #66bfff;
        }

        .is-process.el-step__title {
            color: #66bfff;
        }

        .el-step .is-horizontal .el-step__line {
            top: 13px !important;
        }

        .el-step__title {
            font-size: 14px;
            margin-left: -12px;
        }
    }

    /deep/ .el-result {
        .icon-success {
            fill: #36d9b0
        }
        svg {
            width: 120px;
            height: 120px;
        }
        .el-result__title > p {
            color: #36d9b0;
            font-weight: bold;
        }
    }
</style>
